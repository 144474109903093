.employees {
    display: flex;
    margin: -10px;
    font-size: 14px;
    max-width: 320px;
    width: 100%;
    max-height: 700px;
    position: absolute;
    top: 15%;
    left: 20px;
    &__close {
        // position: absolute;
        // right: 15px;
        // top: 15px;
        margin-top: 3px;
        margin-right: 5px;
        display: flex;
        justify-content: flex-end;
    }
    .MuiTypography-root {
        font-size: 14px;
    }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0;
    }
    .employees__btn {
        text-transform: none;
        color: #0078d4;
        font-weight: 400;
    }
    &__title {
        font-weight: 500;
        font-size: 16px;
    }
    &__search {
        padding: 0 16px;
        &-icon {
            opacity: .6;
        }
    }
    &__wrap {
        position: relative;
    }
    &__workMode {
        position: absolute;
        right: 22%;
        bottom: 0;
        background-color: #44b700;
        box-shadow: 0 0 0 2px #fff;
        height: 8px;
        padding: 0;
        min-width: 8px;
        border-radius: 4px;
        transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        // &::after {
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     border: 1px solid #ccc;
        //     height: 100%;
        //     content: "";
        //     position: absolute;
        //     animation: ripple 1.2s infinite ease-in-out;
        //     border-radius: 50%;
        //     box-sizing: inherit;
        // }
    }
    .employees__drawer {
        max-width: 320px;
        width: 100%;
        .MuiDrawer-paper {
            position: relative;
            min-height: 700px;
            box-sizing: border-box;
            overflow: hidden;
        }
    }
    .employees__list {
        overflow: auto;
        max-height: 48vh;
        .MuiListItemIcon-root {
            min-width: auto;
        }
        &-avatar {
            margin-right: 12px;
            background-color: rgb(122, 117, 116);
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            width: 32px;
            height: 32px;
        }
        &-item {
            padding-top: 6px;
            padding-bottom: 6px;
            &.active {
                background-color: #c7e0f4;
            }
        }
    }
    .employees__content {
        padding: 16px;
        flex-grow: 1;
    }
    &__activity-mode {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 10px;
    }
    &__autocomplete {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        margin-bottom: 10px;
        .MuiChip-root {
            height: 24px;
        }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }
    .MuiAutocomplete-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 8px 8px 3px;
    }
    .multiInput .MuiAutocomplete-tag {
        margin-top: 2px;
        margin-bottom: 2px;
    }
    &__header {
        max-width: 319px;
        width: 100%;
        background-color: #fff;
        margin-bottom: 10px;
    }
}
.employee {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
    .employee__avatar {
        background-color: rgb(122, 117, 116);
        color: #fff;
        width: 100px;
        height: 100px;
        font-size: 38px;
        font-weight: 500;
        margin-right: 24px;
    }
    &__name {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.3;
    }
    &__company {
        font-size: 14px;
        color: #605e5c;
    }
    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        &-content {
            padding: 14px 50px;
            border-radius: 6px;
            background-color: #f5f5f5;
        }
    }
}
@keyframes ripple {
    0% {
        transform: 'scale(.8)';
        opacity: 1;
    }
    100% {
        transform: 'scale(2.4)';
        opacity: 0;
    }
}
.contact-details {
    max-width: 764px;
    &__head {
        display: flex;
        justify-content: space-between;
        margin: 20px 10px;
    }
    &__edit {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 3px 5px;
        transition: all .3s ease;
        border-radius: 3px;
        &:hover {
            background-color: #f3f2f1;
            color: #0078d4;
        }
        &-icon {
            margin-right: 8px;
            svg {
                font-size: 20px;
            }
        }
    }
    &__title {
        font-size: 22px;
    }
    &__section  {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #f3f2f1;
        margin-bottom: 40px;
    }
    &__item {
        flex: 0 0 33%;
        max-width: 33%;
        padding-right: 10px;
        &:last-child,
        &:nth-of-type(3n) {
            .contact-details__content::after {
                border: none;
            }
        }
    }
    &__content {
        padding: 0;
        border: none;
        text-align: left;
        transition: all .3s ease;
        background-color: transparent;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-right: 40px;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 16px;
        cursor: pointer;
        &::after {
            content: '';
            position: absolute;
            right: -5px;
            top: 0;
            border-right: 1px solid #f3f2f1;
            height: 100%;
        }
        &:hover {
            background-color: #f3f2f1;
            .contact-details__copy {
                background-color: #f8f8f8;
                opacity: 1;
                visibility: visible;
            }
        }
        &.no-hover {
            &:hover {
                background-color: transparent;
                cursor: auto;
                .contact-details__copy {
                    background-color: transparent;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
    &__label {
        font-weight: 300;
    }
    &__link {
        color: #0078d4;
        display: block;
        &:hover {
            text-decoration: underline;
        }
    }
    &__info {
        padding: 10px;
    }
    &__copy {
        transition: all .3s ease;
        padding: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        svg {
            font-size: 20px;
            color: #666;
        }
    }
    &_notes {
        border: none;
        .contact-details__item {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0;
        }
        .contact-details__content {
            padding: 0;
            &:hover {
                background-color: transparent;
            }
        }
        .contact-details__link {
            color:#212529;
        }
    }
}
.email-autocomplete {
    position: relative;
    .email-autocomplete__close {
        position: absolute;
        top: 6px;
        right: 4px;
    }
}
.autocomplete-clear-focus .MuiAutocomplete-clearIndicatorDirty, .MuiAutocomplete-root.Mui-focused .MuiAutocomplete-clearIndicatorDirty {
    visibility: visible;
}
#projectTooltip .MuiTooltip-tooltip {
    font-size: 14px;
    font-weight: 400;
}
div.selectedCard,
div.projectCard {
    overflow: inherit;
}
.selectedCard,
.projectCard {
    position: absolute;
    top: 0;
    z-index: 9;
    width: 100%;
    max-width: 1200px;
    .MuiCardHeader-root {
        padding-bottom: 8px;
    }
}
.selectedCard {
    max-width: 1370px;
    &.geoMarkTariffCard {
        max-width: 700px;
    }
}
.salesStatistics {
    max-width: 1014px;
}
.projectStatistics {
    max-width: 1200px;
}
.billBoardDetailedUsageInfo {
    max-width: 1200px;
    &__title {
        font-size: 18px;
        font-weight: 500;
    }
    .MuiTableCell-root {
        padding: 10px 6px;
    }
    .MuiTableContainer-root {
        max-height: 650px;
    }
    .MuiTable-stickyHeader {
        border-collapse: collapse;
    }
    .billboardStatisticsTable {
        overflow: auto;
    }
}
.projectCard {
    max-width: 1400px;
}
.additionalCost {
    max-width: 900px;
    .MuiInputBase-root {
        font-size: 14px;
    }
    .react-datepicker__input-container input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        height: 22px;
        font-size: 14px;
        padding-left: 4px;
        padding-top: 2px;
        padding-bottom: 6px;
        background-color: transparent;
    }
    .react-datepicker__close-icon {
        padding: 0;
        top: -3px;
    }
}
.closeSelectedCard {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
}
.openSelectedCard {
    opacity: 1;
    visibility: visible;
}
.billboardDetailCart {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: auto;
    max-width: 864px;
    transform: translateY(-50%)
}
table {
    .priceTableRow {
        display: flex;
        justify-content: center;
        .priceTableCell {
            border-bottom: none;
            height: auto;
        }
    }
}
.projectTableContainer {
    max-height: 582px;
    .MuiTableCell-root {
        padding: 12px;
        height: 26px;
    }
    .priceTableRow {
        .MuiTableCell-root {
            padding: 2px 0;
        }
        .center {
            text-align: center;
        }
    }
    .tariffEdit {
        .MuiTableCell-root {
            padding: 9px 12px;
            .MuiInputBase-input {
                text-align: center;
            }
            .MuiAutocomplete-inputRoot {
                .MuiInputBase-input {
                    text-align: left;
                }
            }
        }
    }
}
.discountRow {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    &__discount {
        display: flex;
        margin-right: 10px;
    }
}
.linkDiscount {
    color: #3f51b5;
    transition: all .2s ease;
    cursor: pointer;
    margin-right: 14px;
    text-decoration: underline;
    white-space: nowrap;
    font-size: 14px;
    &:hover {
        text-decoration: none;
    }
}
.totalDiscountText {
    font-size: 14px;
}
.projectDiscount {
    padding-bottom: 10px;
    .MuiCardHeader-title {
        font-size: 18px;
    }
    .MuiFormControl-marginDense {
        margin: 0;
    }
    .MuiDialogContent-root {
        padding-bottom: 18px;
        padding-top: 22px;
    }
    .formDiscount {
        display: flex;
        align-items: center;
        .item {
            width: 100%;
            padding: 5px 8px;
            &.discount {
                max-width: 120px;
            }
            &.side {
                max-width: 160px;
            }
            &.type {
                max-width: 200px;
            }
            &.month {
                max-width: 130px;
            }
        }
    }
    .MuiButton-containedPrimary {
        height: 40px;
    }
    .MuiPaper-root {
        max-width: 820px;
    }
    .addDiscount {
        margin-top: 10px;
    }
    .MuiTableCell-sizeSmall {
        padding: 6px;
    }
}
div.editListItem {
    justify-content: space-between;
}
.editProject {
    display: flex;
    align-items: center;
    width: 100%;
    &__item {
        width: 100%;
        max-width: 280px;
        .MuiListItemText-root {
            cursor: pointer;
        }
    }
}
.nameLink {
    color: #3f51b5;
    cursor: pointer;
}
.dateAndSatuses {
    display: flex;
    justify-content: space-between;
}
.dateRange {
    display: flex;
    align-items: center;
    .dateRangeStatus {
        margin-top: 8px;
        margin-bottom: 4px;
    }
    .MuiButton-root {
        height: 39px;
    }
}
.clearPrice {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    cursor: pointer;
}
.priceDiscount {
    position: relative;
    width: 90px;
    text-align: center;
    min-height: 18px;
    .priceDiscount__icon {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        cursor: pointer;
    }
}
.priceDiscountEdit {
    max-width: 100px;
    .MuiSvgIcon-root {
        cursor: pointer;
    }
}

.tableContainer {
    max-height: 540px;
    margin-bottom: 16px;
}
.dateCopy {
    position: relative;
    &:hover {
        .dateCopy__icon {
            display: block;
        }
    }
    .dateCopy__icon {
        display: none;
        position: absolute;
        right: -10px;
        top: 0;
        cursor: pointer;
    }
    .disabled {
        color: #ccc;
        cursor: inherit;
    }
}

.billboardStatisticsTable {
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
    .MuiTableHead-root {
        background-color: #fafafa;
    }
    tr, td, th,
    .MuiTableCell-root {
        border: 1px solid #ccc;
        padding: 2px 5px;
    }
    .MuiTableCell-head {
        font-weight: 500;
        padding: 10px 5px;
    }
}
.constructionStatistics {
    max-width: 1160px;
    .MuiTableContainer-root {
        max-height: 600px;
    }
}
.constructionStatisticsTable {
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    &.MuiTable-stickyHeader {
        border-collapse: collapse;
    }
    .MuiTableHead-root {
        background-color: #fafafa;
        position: sticky;
        top: -1px;
        z-index: 1;
    }
    tr, td, th,
    .MuiTableCell-root {
        border: 1px solid #ccc;
        padding: 2px 5px;
    }
    .MuiTableCell-head {
        font-weight: 500;
        padding: 2px 5px;
    }
    .MuiTableFooter-root {
        position: sticky;
        bottom: -1px;
        z-index: 1;
        width: 100%;
        background-color: #fff;
        td {
            font-weight: 500;
            font-size: 14px;
            color: #333;
            padding: 5px;
        }
    }
}
.projectDiscount {
    .discount-container {
        max-height: 450px;
        max-width: 100%;
    }
}
.employeeCallModal {
    .MuiDialog-paper {
        max-width: 500px;
        width: 100%;
    }
    &__title {
        font-size: 16px;
        font-weight: 400;
    }
    .link {
        margin-left: 8px;
        &::after {
            content: ",";
            position: absolute;
        }
        &:last-child {
            &:after {
                content: '';
            }
        }
    }
}
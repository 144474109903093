.selectedBillsCard {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 9;
    max-width: 1200px;
    box-sizing: border-box;
    .MuiCardHeader-root {
        padding-bottom: 16px;
    }
    .MuiFormControl-root {
        min-width: 250px !important;
        max-width: 300px !important;
    }
}

.closeSelectedBillsCard {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
}
.openSelectedBillsCard {
    opacity: 1;
    visibility: visible;
}
.totalBillsAmount{
    font-weight: bold;
    text-align: right;
    font-size: large;
}
.widget-panel {
    position: absolute;
    top: 0;
    display: inline-block;
    transition-property: -webkit-transform,transform,opacity;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.0,0.0,0.2,1);
    .widget-panel__btn {
        border-radius: 0;
        position: absolute;
        top: 0.5rem;
        z-index: 9;
        width: 26px;
        &.bottom {
            bottom: 1rem;
            top: auto
        }
    }
}
.widget-panel-task {
    .widget-panel {
        bottom: 18px;
        right: 0;
        top: auto;
        min-height: 66px;
    }
    .Toastify__toast-container {
        position: static;
    }
}
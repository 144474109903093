// /*
//     DEMO STYLE
// */

// body {
//     background: #fafafa;
// }

// p {
//     font-size: 1.1em;
//     font-weight: 300;
//     line-height: 1.7em;
//     color: #999;
// }

// a,
// a:hover,
// a:focus {
//     color: inherit;
//     text-decoration: none;
//     transition: all 0.3s;
// }

// .navbar {
//     padding: 15px 10px;
//     background: #fff;
//     border: none;
//     border-radius: 0;
//     margin-bottom: 40px;
//     box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
// }

// .navbar-btn {
//     box-shadow: none;
//     outline: none !important;
//     border: none;
// }

// .line {
//     width: 100%;
//     height: 1px;
//     border-bottom: 1px dashed #ddd;
//     margin: 40px 0;
// }

// i,
// span {
//     display: inline-block;
// }

// .btn.dropdown-toggle::after {
//     content: none;
// }

// /* ---------------------------------------------------
//     SIDEBAR STYLE
// ----------------------------------------------------- */

// .wrapper {
//     display: flex;
//     align-items: stretch;
// }

// #sidebar {
//     // min-width: 250px;
//     // max-width: 250px;
//     // /*background: #7386D5;*/
//     // // background: #080808;
//     // // color: #fff;
//     // transition: all 0.3s;
// }

//     #sidebar.active {
//         min-width: 80px;
//         max-width: 80px;
//         text-align: center;
//     }

//         #sidebar.active .sidebar-header h3,
//         #sidebar.active .CTAs {
//             display: none;
//         }

//         #sidebar.active .sidebar-header strong {
//             display: block;
//         }

//     #sidebar ul li a {
//         text-align: left;
//     }

//     #sidebar.active ul li a {
//         padding: 20px 10px;
//         text-align: center;
//         font-size: 0.85em;
//     }

//         #sidebar.active ul li a i {
//             margin-right: 0;
//             display: block;
//             font-size: 1.8em;
//             margin-bottom: 5px;
//         }

//     #sidebar.active ul ul a {
//         padding: 10px !important;
//     }

//     #sidebar.active .dropdown-toggle::after {
//         top: auto;
//         bottom: 10px;
//         right: 50%;
//         -webkit-transform: translateX(50%);
//         -ms-transform: translateX(50%);
//         transform: translateX(50%);
//     }

//     #sidebar .sidebar-header {
//         padding: 10px 20px 10px 10px;
//         /*background: #6d7fcc;*/
//         background: #111;
//     }

// #sidebar .sidebar-lang {
//     padding: 10px 10px 0px 10px;
//     width: 10px;
// }
//         #sidebar .sidebar-header strong {
//             display: none;
//             font-size: 1.8em;
//         }

//     #sidebar ul.components {
//         padding: 20px 0;
//         border-bottom: 1px solid #222;
//     }

//     #sidebar ul li a {
//         padding: 10px;
//         font-size: 1.1em;
//         display: block;
//     }

//         #sidebar ul li a:hover {
//             color: #fff;
//             background: #333;
//         }

//         #sidebar ul li a i {
//             margin-right: 10px;
//         }

//     #sidebar ul li.active > a,
//     a[aria-expanded="true"] {
//         color: #fff;
//         background: #111;
//     }

// #sidebar .bottom_button {
//     position: absolute;
//     display: inline-block;
//     bottom: 0;
//     left: 15px;
// }

// a[data-toggle="collapse"] {
//     position: relative;
// }

// .dropdown-toggle::after {
//     display: block;
//     position: absolute;
//     top: 50%;
//     right: 20px;
//     transform: translateY(-50%);
// }

// ul ul a {
//     font-size: 0.9em !important;
//     padding-left: 30px !important;
//     /*background: #6d7fcc;*/
//     background: #111;
// }

// ul.CTAs {
//     padding: 20px;
// }

//     ul.CTAs a {
//         text-align: center;
//         font-size: 0.9em !important;
//         display: block;
//         border-radius: 5px;
//         margin-bottom: 5px;
//     }

// a.download {
//     background: #fff;
//     color: #111;
// }

// a.article,
// a.article:hover {
//     background: #111 !important;
//     color: #fff !important;
// }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    &.map-page {
        padding: 0;
    }
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

/*@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
    }

    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }

    #sidebar .sidebar-header strong {
        display: block;
    }

    #sidebar ul li a {
        padding: 20px 10px;
    }

        #sidebar ul li a span {
            font-size: 0.85em;
        }

        #sidebar ul li a i {
            margin-right: 0;
            display: block;
        }

    #sidebar ul ul a {
        padding: 10px !important;
    }

    #sidebar ul li a i {
        font-size: 1.3em;
    }

    #sidebar {
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
    
    #sidebar .sidebar-lang {
        padding: 10px;
    }
}*/


/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
a {
    color: #fff;
    text-decoration: none;
}
$accent: #3f51b5;
.sidebar {
    background-color: #303841;
    color: #fff;
    width: 100%;
    transition: max-width 0.1s ease;
    z-index: 3;
    overflow-x: hidden;
    position: relative;
    &_large {
        max-width: 240px;
        .sidebar__logo-desctop {
            display: block;
        }
        .sidebar__logo-mobile {
            display: none;
        }
    }
    &_small {
        max-width: 72px;
        .dropdown__icon {
            right: 3px;
        }
        .dropdown__item a {
            padding-left: 24px;
        }
        .sidebar__logo-desctop {
            display: none;
        }
        .sidebar__logo-mobile {
            display: block;
        }
        .login-menu {
            &::after {
                display: none;
            }
        }
        .profile {
            padding: 10px;
            justify-content: center;
            &__wrap {
                margin-right: 0;
                margin-bottom: 10px;
                width: 60px;
                height: 60px;
                font-size: 26px;
            }
            &__login {
                display: none;
            }
            &__logout {
                span {
                    margin: 0;
                    font-size: 35px;
                }
                .text {
                    display: none;
                }
            }
        }
        .menu-nav {
            &__item {
                a,
                .menu-nav__toggle {
                    .text {
                        opacity: 0;
                        transform: translate3d(-25px, 0px, 0px);
                        transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                    }
                }
            }
        }
        .call-phone {
            left: 10px;
            bottom: 10px;
        }
        .profile-nav {
            &__item {
                a, .login-menu {
                    .text {
                        opacity: 0;
                        transform: translate3d(-25px, 0px, 0px);
                        transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                    }
                }
            }
        }
        .sidebar-lang #lang_nav_menu {
            padding: 5px;
        }
        .profile-nav__drop li a {
            padding-left: 16px;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px;
        cursor: pointer;
        border-bottom: 1px solid #7070708a;
    }
    &__logo {
        font-size: 20px;
        font-weight: 700;
    }
    &__logo-mobile {
        display: none;
    }
    &__burger {
        display: flex;
        align-items: center;
        .material-icons {
            font-size: 30px;
        }
    }
}
.menu-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        position: relative;
        a,
        .menu-nav__toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin: 12px 8px;
            font-size: 14px;
            color: #fff;
            border-radius: 4px;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            .text {
                position: relative;
                display: block;
                white-space: nowrap;
                opacity: 1;
                transform: translate3d(0px, 0px, 0px);
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                margin-left: 44px;
            }
            &:hover {
                text-decoration: none;
                background-color: rgba(63, 81, 181, 0.6);
            }
            &.is-active {
                text-decoration: none;
                background-color: $accent;
            }
            .material-icons {
                position: absolute;
                width: auto;
            }
        }
    }
}
.sidebar {
    .dropdown {
        visibility: hidden;
        opacity: 0;
        display: none;
        transition: all 0.5s ease;
        margin: 0;
        padding: 0;
        &.active {
            visibility: visible;
            opacity: 1;
            display: block;
        }
        &__item {
            list-style: none;
            a {
                margin: 0 8px;
                padding-left: 30px;
            }
        }
        &__icon {
            right: 10px;
        }
    }
}
.profile-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        a,
        .login-menu {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin: 12px 8px;
            font-size: 14px;
            color: #fff;
            border-radius: 4px;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            cursor: pointer;
            .text {
                position: relative;
                display: block;
                white-space: nowrap;
                opacity: 1;
                transform: translate3d(0px, 0px, 0px);
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                margin-left: 44px;
            }
            .material-icons {
                width: 24px;
                height: 24px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #303841;
                font-size: 18px;
                position: absolute;
            }
            &:hover {
                text-decoration: none;
                background-color: rgba(63, 81, 181, 0.6);
            }
        }  
    }
    &__drop {
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        li {
            list-style: none;
            a {
                padding-left: 60px;
                .material-icons {
                    background-color: transparent;
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    &__wrap {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $accent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 30px;
        margin-right: 15px;
    }
    &__login {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 5px;
        span {
            margin-right: 7px;
            font-size: 20px;
        }
    }
    &__logout {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        transition: none;
        span {
            margin-right: 10px;
            font-size: 17px;
        }
        .text {
            font-size: 14px;
        }
    }
}
.sidebar-lang {
    margin: 12px 8px;
    #lang_nav_menu {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        svg {
            margin-right: 5px;
        }
        &::after {
            display: none;
        }
        &:focus {
            box-shadow: none;
            outline: none;
            border-color: $accent;
        }
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        span {
            margin-right: 5px;
            margin-top: -5px;
        }
    }
}
.lang-icon {
    display: flex;
    align-items: center;
    .flag-icon {
        margin-top: -3px;
        margin-right: 3px;
    }
}
button.lang-button {
    color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    margin-left: 1px;
    min-width: 54px;
    padding: 5.5px 3px;
}
.login-menu {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        border: 6px solid transparent; 
        border-top: 6px solid #fff;
        right: 10px;
        top: 3px;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 6px;
    }
}
.sidebar-version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #e0e0e0;
    font-size: 12px;
}
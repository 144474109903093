.totalStatistics {
    font-weight: 700;
    text-align: right;
    margin-top: 15px;
    font-size: 16px;
}
.regionNameLink {
    #regionNameLinkBtn {
        white-space: normal;
        text-align: left;
        padding: 0;
    }
    .dropdown-toggle {
        color: #3f51b5;
        &::after {
            display: none;
        }
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}
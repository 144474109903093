div.add-billboard {
    max-height: 600px;
    overflow-y: auto;
    padding-top: 5px;
    .MuiInputBase-input.Mui-disabled {
        color: #333;
    }
}
.selectedCard .cardActions {
    padding-top: 15px;
}
label.allowed-sales {
    margin: 0;
    margin-left: -6px;
    display: flex;
    align-items: center;
    span {
        padding: 2px;
        line-height: 1;
    }
}
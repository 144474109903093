.selectedTariffCard {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    z-index: 9;
    max-width: 1700px;
    box-sizing: border-box;
    .MuiCardHeader-root {
        padding-bottom: 16px;
    }
}
.closeSelectedTariffCard {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
}
.openSelectedTariffCard {
    opacity: 1;
    visibility: visible;
}
.tariffEdit {
    .MuiInputBase-input {
        font-size: 14px;
    }
}
div.copyRow {
    position: absolute;
    display: block;
    right: -12px;
    bottom: 32px;
    transition: all .3s ease;
    display: none;
    svg {
        font-size: 14px;
        display: block;
        margin-bottom: 1px;
        cursor: pointer;
    }
}
.tariffFormControl {
    &:hover,
    .Mui-focused {
        .copyRow {
            display: block;
        }
    }
}
.billboardTariffTable {
    .MuiInputBase-input {
        text-align: center;
        font-size: 14px;
    }
}
.map-container {
    position: relative;
}

.map-cards {
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    max-width: 506px;
}
.map-cards__block {
    position: relative;
    z-index: 1;
}
.modal-billboard-detail {
    position: relative;
    max-width: 450px;
}
.map-cards input[type="date"]::-webkit-clear-button {
    display: none;
}
.map-cards input[type="date"]::-ms-clear {
    display: none;
}
.reset-button {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 9;
}
.status-date-range input.MuiOutlinedInput-inputMarginDense {
    padding: 10.5px 6px;    
}
.status-date-range div.MuiOutlinedInput-inputSelect {
    padding-left: 10px;
}
.status-date-range .MuiSelect-iconOutlined {
    right: 2px;
}

.advancedSearch {
    padding: 5px;
    max-width: 350px;
    width: 100%;
    position: fixed;
    right: 62px;
    top: 4px;
    z-index: 2;
    display: flex;
    align-items: center;
}
.advancedSearch .MuiAutocomplete-root,
.advancedSearch .advancedAutocomplete-row {
    width: 100%;
}
.filter-billboard-row {
    position: absolute;
    top: 60px;
    right: 0;
}
button.filter-billboard__icon {
    position: absolute;
    right: -26px;
    border-radius: 0;
    width: 26px;
    height: 36px;
}
button.filter-billboard__icon-clear {
    position: absolute;
    right: -58px;
    min-height: 28px;
    width: 28px;
    height: 28px;
    margin-left: 3px;
}
button.filter-billboard__icon-clear .MuiSvgIcon-root {
    font-size: 1.4rem;
}
.filter-billboard__icon .MuiSvgIcon-root {
    font-size: 2rem;
}
.billboardSelected {
    position: relative;
}
button.resetFilter {
    position: fixed;
    top: 5px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 99;
}
.PhotocontrolCard {
    max-width: 1000px;
}
.fancyImages{
    //max-height: 100px;
    
    max-width: 100px;
    padding: 5px;
}

.slick-slide {
    //max-height:80px;
 }
 
 .slick-slide img {
    //max-height:80px;
 }
 .slick-dots{
    max-height: 20px;
    overflow: hidden;
 }
 .photocontrol-total{
    font-weight: bold;
 }
.mainCarousel {
    .mainCarousel__item img {
        max-width: 100%;
        display: block;
        height: auto;
    }
    .slick-arrow {
        &::before {
            opacity: 1;
        }
    }
    .slick-prev {
        left: 3px;
        z-index: 2;
    }
    .slick-next {
        right: 3px;
    }
    .slick-dots {
        bottom: -10px;
    }
    .slick-dots li,
    .slick-dots li button,
    .slick-dots li button,
    .slick-dots li button:before {
        width: 10px;
        height: 10px;
    }
    .slick-dots li {
        margin: 0 3px;
    }
    .slick-dots li button:before {
        line-height: 10px;
    }
}

.modal-billboard-detail .MuiTableCell-root {
    padding: 10px;
}
.client-modal {
  .MuiListSubheader-root {
    line-height: 1;
    padding-top: 5px;
  }
}

div.phone-row {
  display: flex;

  &__item {
    margin-left: 8px;
    max-width: 120px;
    width: 100%;
  }
}

.billboardDetailRow {
  position: relative;

  &__img {
    width: 100%;
    height: 130px;
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }

  &__image {
    max-width: 100%;
    cursor: pointer;
  }
}

button.billboardDetailRow__delete {
  position: absolute;
  top: -24px;
  right: -10px;
  width: 26px;
  height: 26px;
  min-height: 26px;
  z-index: 3;

  svg {
    font-size: 1.2rem;
  }
}

button.billboardDetailRow__upload {
  position: absolute;
  bottom: -16px;
  right: -10px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  min-height: 26px;
  background: #d5d5d5;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    font-size: 1.2rem;
  }
}
#photo-menu {
    label {
        margin-bottom: 0;
        cursor: pointer;
    }
}
.projects-list {
    padding-top: 8px;
}
.linkProjectName {
    transition: all 0.3s ease;
    font-size: 14px;
    color: #303f9f;
    display: inline-block;
    font-weight: 500;
    &:hover {
        color: #303f9f;
        text-decoration: underline;
        cursor: pointer;
    }
}
.projectName {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 5px;
}

.billboardCarousel {
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }

  .carousel-control-prev {
    left: -16px;
  }

  .carousel-control-next {
    right: -16px;
  }

  .carousel-indicators {
    bottom: -15px;
    flex-wrap: wrap;
    display: none;

    li {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ccc;
      opacity: 1;
      border: none;
      margin: 3px;

      &.active {
        background-color: #303f9f;
      }
    }
  }
}

.tt {
  transition: all 0.3s ease;
  border: none;
  background-color: transparent;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: #303f9f;
  }
}

.companyAutocomplete {
  position: relative;
  margin-top: 4px;

  .MuiAutocomplete-clearIndicator {
    margin-right: 30px;
    visibility: visible;
  }

  .companyAutocomplete__label {
    position: absolute;
    right: 6px;
    top: 4px;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
  }
}
.billboardTitle {
    margin-top: 8px;
}
.billboardDates {
    font-size: 13px;
}
.project-row {
    display: flex;
    align-items: center;
    &__icon {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 6px;
    }
}
.additionalTariffs {
    max-width: 950px;
}
.additionalTariffsTable {
    max-height: 400px;
    min-height: 400px;
}
.additionalTariffs {
    .MuiInputBase-root {
        line-height: 1;
        font-size: 14px;
        input {
            padding: 3px 0 4px;
            text-align: center;
        }
    }
    .MuiFormControl-root {
        margin: 0;
        line-height: 1;
        .MuiSelect-icon {
            top: calc(50% - 14px);
            right: -10px;
        }
        .MuiSelect-select {
            padding-right: 0;
        }
    }
}
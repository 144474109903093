.billboardCard {
    max-width: 800px;
    &__title {
        font-size: 18px;
        font-weight: 500;
    }
    &__tabs {
        position: absolute;
        top: -46px;
        background-color: #fff;
        border-radius: 6px 6px 0 0;
    }
    &__tab {
        span {
            text-transform: none;
            font-size: 16px;
            font-weight: 400;
        }
    }
    .MuiCardContent-root {
        padding-top: 0;
    }
    .billboard-popup-list {
        box-shadow: none;
        margin-top: 0;
        th, td {
            padding-left: 0;
        }
    }
    .link {
        font-size: 14px;
    }
    &__getLink {
        text-align: right;
        margin-bottom: 5px;
    }
    .billboard-container {
        max-height: 365px;
    }
}
.billboardMap {
    width: 100%;
    height: 240px;
    margin-top: 10px;
}
.mainSlider {
    .mainSlider__item {
        position: relative;
        cursor: pointer;
        img {
            max-width: 100%;
            display: block;
            height: auto;
        }
        &:hover {
            &::after {
                opacity: 1;
                visibility: visible;
                font-size: 35px;
            }
        }
        &::after {
            font-family: 'Material Icons';
            content: "zoom_in";
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .3);
            transition: all .3s ease-in;
            opacity: 0;
            visibility: hidden;
        }
    }
}
.thumbSlider {
    margin-top: -6px;
    .slick-slide > div {
        padding-top: 2px;
        padding-left: 2px;
    }
    .slick-slide {
        cursor: pointer;
        img {
            max-width: 100%;
            display: block;
            height: auto;
        }
    }
    .slick-current {
        img {
            outline: 2px solid #1976d2;
        }
    }
    .slick-arrow  {
        z-index: 9;
    }
    .slick-prev {
        left: 5px;
    }
    .slick-next {
        right: 5px;
    }
}
.billboard-status {
    .billboard-status__label {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        margin: 0;
        height: 22px;
        padding-left: 25px;
    }
    .billboard-status__checkbox {
        padding: 0;
        position: absolute;
        left: 0;
    }
    .MuiFormControlLabel-label {
        font-size: 11px;
    }
}
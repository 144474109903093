.cluster-marker {
    cursor: pointer;
    width: 40px;
    height: 40px;
    left: -20px;
    top: -20px;
    border: 5px solid #004336;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
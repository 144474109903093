.employee-modal {
    .MuiDialog-paper {
        padding: 8px 16px;
    }
    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        &-left {
            flex: 0 0 20%;
            max-width: 20%;
            position: relative;
        }
        &-right {
            flex: 0 0 80%;
            max-width: 80%;
            padding-left: 20px;
        }
    }
    &__photo-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #605e5c;
        color: #ffffff;
        position: absolute;
        left: -14px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        &:hover {
            width: 36px;
            height: 36px;
            left: -16px;
        }
        svg {
            font-size: 22px;
        }
    }
    .employee-modal__avatar {
        width: 100px;
        height: 100px;
        svg {
            font-size: 52px;
        }
    }
    .employee-modal__text-field {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__content {
        margin-bottom: 24px;
    }
    &__title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .MuiInputBase-root {
        .Mui-disabled {
            color: rgba(0, 0, 0, 0.87);
        }
    }
    .MuiInput-underline.Mui-disabled:before {
        border-bottom-style: solid;
        border-color: #cccccc;
    }
    .MuiDialogTitle-root {
        min-height: 64px;
    }
    .getAddressAutocomplete-row input {
        min-width: auto;
    }
}
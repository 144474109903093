.circle {
  //background-image: conic-gradient(#78c2ad 50%, #ffce67 50% 75%, #fff 0);
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: all .2s;
  margin: 0;
  &.active {
    z-index: 2;
    &:after {
      background-color: #303f9f;
    }
  }
  &:after {
    content: '';
    position: absolute;
    background-color: #000;
    width: 55%;
    height: 55%;
    box-sizing: border-box;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle__title {
    font-size: 8px;
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 3;
    font-family: sans-serif;
  }
  &:hover {
    width: 48px;
    height: 35px;
    margin-top: -5px;
    margin-left: -5px;
    z-index: 3;
    &:after {
      width: 80%;
      height: 80%;
      background-color: #303f9f;
    }
    .circle__title {
      opacity: 1;
    }
  }
}
div.filter-billboard {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    padding: 0.6rem 0.5rem 0.5rem;
    margin-left: 0.05rem;
    margin-top: -116px;   
    &.active {
        opacity: 1;
        visibility: visible;
        margin-bottom: 0.5rem;
        margin-top: 0;
    }
}
div.next-billboard-filter {
    border: 1px solid #dddddd;
    padding: 8px 5px;
    position: relative;
    overflow: inherit;
    display: inline-block;
    .MuiFormControl-root {
        margin: 3px 0.25rem;
    }
    &.MuiCard-root {
        box-shadow: none;
    }
    .filter-billboard__icon-clear {
        right: -20px;
        top: -14px;
    }
    &::before {
        content: "Фильтр";
        position: absolute;
        font-size: 14px;
        top: -10px;
        left: 16px;
        background-color: #fff;
        padding-left: 6px;
        padding-right: 6px;
    }
}
.saveBrandGeoObjects {
    border: 1px solid #dddddd;
    padding: 8px 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .status-date-range {
        margin-top: 3px;
        margin-bottom: 3px;
        .MuiFormControl-root {
            width: 50%;
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}
.advanced-search-option {
  &__name {
    display: block;
  }

  &__GID,
  &__AreaName {
    opacity: 0.7;
    font-size: 12px;
  }
}

.multiInputAutocomplate {
  margin-top: 0.7rem;
}

.multiInput {
    .MuiAutocomplete-tag {
        margin-top: 0;
        height: 26px;
    }
    .MuiAutocomplete-input {
        padding: 0 !important;
    }
    .MuiInputBase-root.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        min-height: 40px;
        padding: 8px 8px 0;
    }
}

.datesShifSelect {
  .MuiInputBase-root {
    line-height: 1.73;
    text-transform: none;
    font-weight: 400;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input {
    padding-bottom: 2px;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }
}

.ProjectAddSnackbar {
  .MuiSnackbarContent-root {
    min-width: 190px;
    padding: 0 8px 0 6px;
  }

  .MuiSnackbarContent-action {
    padding-left: 2px;
  }

  &.MuiSnackbar-anchorOriginTopCenter {
    transform: translateX(20%);
    top: 10px;
  }

  .MuiButton-root {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.54);
      background-color: #ccc;
    }
  }
}

.successSnackbar .MuiSnackbarContent-root {
  background-color: green;
}

.preview a {
  color: #0056b3;
}

.messageShackbar {
    &.MuiSnackbar-root {
        z-index: 99999;
    }
  .MuiButtonBase-root {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 28px;
  }

  .MuiSnackbarContent-root {
    padding-right: 35px;
  }

  &.success {
    .MuiSnackbarContent-root {
      background-color: #28a745;
    }
  }

  &.error {
    .MuiSnackbarContent-root {
      background-color: rgb(252, 40, 40);
    }
  }

  &.warning {
    .MuiSnackbarContent-root {
      background-color: orange;
    }
  }
}

.agenciesAutocomplete {
  position: relative;
  margin-top: 4px;

  .MuiAutocomplete-clearIndicator {
    margin-right: 30px;
    visibility: visible;
  }

  .agenciesAutocomplete__label {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
    height: 30px;
  }
}

.addressCompany {
  margin: 5px 0;
}

.dateInterval {
  position: absolute;
  top: 0;
  left: 200px;
  right: 0;
  margin: auto;
  max-width: 580px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 0 5px;
  z-index: 999;
  transition: all 0.3s ease;
  cursor: pointer;

  &__GID {
    position: absolute;
    background-color: #fff;
    padding: 5px;
    left: -96px;
    .MuiFormControl-root {
      max-width: 94px;
      input {
        color: #333;
        font-size: 14px;
        padding: 5px 2px;
        text-align: center;
      }
    }
  }

  &__content {
    margin: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__title {
    font-weight: 600;
    display: block;
    padding: 4px 2px;

    &:first-child {
      border-bottom: 2px solid #333333;
    }
  }

  &__span {
    display: block;
    padding: 4px 2px;
    text-transform: capitalize;

    &:first-child {
      border-bottom: 2px solid #333333;
    }
  }

  .dateInterval__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 0;
    height: 28px;
    min-height: 28px;
    transform: translateY(100%);
    box-shadow: none;
    box-sizing: border-box;
  }
}

div.agencyActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 16px;
}

.draggableModal {
  cursor: move;

  &.projectDiscount {
    width: 950px;
    position: absolute;
    top: 3rem;
    z-index: 99999;
  }

  &.hidden {
    display: none;
  }

  &.show {
    display: block;
  }

  .MuiCardHeader-root {
    padding-bottom: 0;
  }

  .MuiCardContent-root {
    padding-top: 4px;
  }
}
.editInputTariff {
    .MuiInputBase-input {
        font-size: 13px;
    }
}
.projectItemSelect {
    &__type {
        color: #777777;
        font-size: 13px;
        margin-left: 8px;
    }
}
.status-date-range {
    .MuiFormControl-marginDense {
        margin: 0;
    }
}
.billboardList,
.brandsCard,
.searchOblectsBrand {
    position: absolute;
    top: 3rem;
    left: 8px;
    z-index: 8;
    max-width: 1000px;
    &.MuiCard-root {
        overflow: inherit;
    }
    &__left {
        display: flex;
        align-items: center;
        padding-right: 26px;
        position: relative;
        // &::after {
        //     content: '';
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        //     width: 1px;
        //     height: 100%;
        //     bottom: 0;
        //     background-color: #cccccc;
        // }
        &-label {
            margin: 0;
            margin-right: 10px;
            font-size: 16px;
        }
        &-span {
            margin-left: 5px;
            margin-right: 15px;
            font-size: 16px;
        }
    }
    &__right {
        .MuiButton-root {
            margin-left: 12px;
        }
    }
}
.brandsCard {
    max-width: 1000px;
}
.billboardList {
    min-width: 1324px;
    &__title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 12px;
    }
    &__right {
        margin-bottom: 10px;
    }
}
.searchOblectsBrand {
    min-width:  1200px;
}

.MuiCardContent-root.billboardList__table {
    padding-top: 0;
}
.calculateDistance {
    max-height: 600px;
    .MuiTableRow-root {
        vertical-align: top;
    }
    &__tableCell {
        color: #3f51b5;
        cursor: pointer;
        &Km {
            font-size: 13px;
            padding-left: 5px;
        }
    }
}

.geoMarkName {
    position: relative;
    cursor: pointer;
    .MuiSvgIcon-root {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 16px;
        display: none;
        color: #0056b3;
    }
    .MuiOutlinedInput-root {
        font-size: 14px;
        input {
            padding-left: 6px;
            padding-right: 6px;
        }
    }
    &:hover {
        .MuiSvgIcon-root {
           display: block;
        }
    }
}
.nextBillboardRow {
    position: relative;
}
.nextBillboardStatuses {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #cccccc;
    background-color: rgb(204, 204, 204);
    box-sizing: border-box;
    position: absolute;
    left: -20px;
    top: 2px;
    cursor: pointer;
}
.button-group {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.editProjectCreatorName {
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &:hover {
        .MuiSvgIcon-root{
            display: block;
        }
    }
    .MuiSvgIcon-root{
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        color: #0056b3;
        display: none;
    }
}
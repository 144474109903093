
$accent: #3f51b5;
button {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.MuiAutocomplete-root {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0 8px;
    }
    .MuiFormControl-marginNormal {
        margin-top: 8px;
        margin-bottom: 4px;
    }
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
}

.call-phone {
    position: absolute;
    display: block;
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: $accent;
    border-radius: 50%;
    box-shadow:
      0 0 0 0em rgba($accent, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
    cursor: pointer;
    bottom: 12px;
    left: 12px;
    z-index: 999;
    color: #fff;
    border: none;
    outline: none;
    &.call-close::after {
        content: 'close';
    }
    &__number {
        span {
            opacity: .7;
        }
    }
}

.call-phone::before,
.call-phone::after {
    position: absolute;
    content: "";
}

.call-phone::before {
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(#fff, 0.1);
    border-radius: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
}

.call-phone::after {
    font-family: "Material Icons";
    content: "phone";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 1;
}

.call-phone.is-animating {
    animation: phone-outer 3000ms infinite;

    &::before {
        animation: phone-inner 3000ms infinite;
    }

    &::after {
        animation: phone-icon 3000ms infinite;
    }
}

@keyframes phone-outer {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0.1),
        0em 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0.5em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    }

    @keyframes phone-inner {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0);
    }
    33.3333% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0.9);
    }
    66.6666% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
}

@keyframes phone-icon {
    0% { transform: translate3d(0em, 0, 0); }
    2% { transform: translate3d(0.01em, 0, 0); }
    4% { transform: translate3d(-0.01em, 0, 0); }
    6% { transform: translate3d(0.01em, 0, 0); }
    8% { transform: translate3d(-0.01em, 0, 0); }
    10% { transform: translate3d(0.01em, 0, 0); }
    12% { transform: translate3d(-0.01em, 0, 0); }
    14% { transform: translate3d(0.01em, 0, 0); }
    16% { transform: translate3d(-0.01em, 0, 0); }
    18% { transform: translate3d(0.01em, 0, 0); }
    20% { transform: translate3d(-0.01em, 0, 0); }
    22% { transform: translate3d(0.01em, 0, 0); }
    24% { transform: translate3d(-0.01em, 0, 0); }
    26% { transform: translate3d(0.01em, 0, 0); }
    28% { transform: translate3d(-0.01em, 0, 0); }
    30% { transform: translate3d(0.01em, 0, 0); }
    32% { transform: translate3d(-0.01em, 0, 0); }
    34% { transform: translate3d(0.01em, 0, 0); }
    36% { transform: translate3d(-0.01em, 0, 0); }
    38% { transform: translate3d(0.01em, 0, 0); }
    40% { transform: translate3d(-0.01em, 0, 0); }
    42% { transform: translate3d(0.01em, 0, 0); }
    44% { transform: translate3d(-0.01em, 0, 0); }
    46% { transform: translate3d(0em, 0, 0); }
}

.call-popup {
    color: #333;
    transition: all .3s ease;
    &__content {
        position: fixed;
        left: 75px;
        bottom: 75px;
        background: #fff;
        box-shadow: 0 3px 14px rgba(17,34,51,0.16);
        padding: 15px;
        z-index: 999;
        max-width: 320px;
    }
    &__backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 99;
    }
    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    &.hide {
        visibility: hidden;
        opacity: 0;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    .input-group {
        padding: 10px 0 15px;
        input, button {
            border-radius: 0;
        }
        input:focus {
            box-shadow: none;
            outline: none;
            border-color: #28a745;
        }
    }
}

.form-group {
    .input-group-prepend {
        z-index: 2;
    }
    .input-group {
        select {
            margin-left: -3px;
        }
    }
}
.date-group {
    input {
        line-height: 32px;
        max-width: 332px;
        width: 100%;
        padding-left: 10px;
        &:last-child {
            margin-left: -1px;
        }
    }
    .react-datepicker__input-container {
        min-width: 332px;
        width: 100%;
    }
    .react-datepicker-wrapper {
        max-width: 330px;
        width: 100%;
    }
}
.customer-search {
    .btn {
        width: 100%;
    }
}
.call-phone-scroll {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

/*-------------------------------------------*\
    Preloader style
\*-------------------------------------------*/

.main-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99999;
    .pulse {
        position: relative;
        left: 50%;
        top: 50vh;
        margin-left: -40px;
        margin-top: -40px;
        &:after, &:before {
            content: '';
            position: absolute;
            width: 80px;
            height: 80px;
            border: 5px solid $accent;
            border-radius: 50%;
        }
        &:before {
            animation: pulse-outer .8s ease-in infinite;
        }
        &:after {
            animation: pulse-inner .8s linear infinite 
        }
    }
}

@keyframes pulse-outer {
    0% {
        opacity: 1
    }
    50% {
        opacity: .5
    }
    100% {
        opacity: 0
    }
}

@keyframes pulse-inner {
    0% {
        opacity: 0;
        transform: scale(0)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.preorder {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
    }
}

/*-------------------------------------------*\
    Edit billboard style
\*-------------------------------------------*/

.billboard-popup-list {
    margin-top: 15px;
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.billboard-edit {
    &.hide {
        display: none;
    }
    &.show {
        display: block;
    }
    &__select {
        width: 100%;
        .MuiInput-underline {
            &:before {
                border: none !important;
            }
        }
        .MuiSelect-select:focus {
            background-color: transparent;
        }
        .MuiInput-underline:after {
            display: none;
        }
        .MuiInputBase-input {
            padding: 0;
            padding-right: 24px;
        }
    }
    &-tabel {
        &__head {
            tr {
                th {
                    font-size: 16px;
                }
            }
        }
        &__tr {
            width: 220px;
        }
    }
    &_edit {
        .MuiSelect-icon {
            display: none;
        }
        .MuiInputBase-root.Mui-disabled {
            color: inherit;
        }
    }
    button {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}
.btn-edit-group {
    .btn-cencel {
        margin-right: 10px;
    }
}

.letter-button-group {
    .MuiButtonGroup-root {
        display: flex;
    }
    .MuiButtonGroup-grouped {
        min-width: 40px;
    }
}


/* TOASTIFY */

.Toastify__toast-container{
    padding: 0;
}

.Toastify__toast {
    padding: 0;
    margin-bottom: 0;
}

.Toastify__toast-container--bottom-right {
    bottom: 0.5em;
    right: 0.5em;
}

/*-------------------------------------------*\
    Notification style
\*-------------------------------------------*/

div.notificationRow {
    min-height: 72px;
    min-width: 317px;
    max-width: 396px;
    background: #495057;
    border: none;
    padding: 12px;
    padding-right: 10px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 0;
}
.notification {
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
        .notification__desc {
            margin-bottom: 0;
        }
    }
    &__title {
        font-weight: 500;
        margin-bottom: 8px;
        text-align: center;
    }
    &__content {
        background: #343a40;
        padding: 12px;
        box-sizing: border-box;
        font-weight: 300;
        text-align: center;
        position: relative;
    }
    svg.notification__more {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 26px;
        cursor: pointer;
        transition: all 0.3s ease;
        &.toggle {
            transform: rotate(180deg);
        }
    }
    &__desc {
        font-weight: 300;
        margin-bottom: 8px;
        text-align: left;
        white-space: pre-wrap;
        margin-right: 18px;
    }
    .buttonGroup {
        display: flex;
    }
    button {
        color: #fff;
        background-color: #6c757d;
        box-shadow: none;
        border-radius: 0;
        text-transform: none;
        font-weight: 400;
        padding: 8px;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 5px;
        line-height: 1.2;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            box-shadow: none;
        }
    }
    &__infoClient {
        text-align: left;
        color: #fff;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.4;
    }
    &__textField {
        padding-bottom: 14px;
        padding-top: 4px;
        .MuiInputBase-root,
        .MuiFormLabel-root {
            color: #fff;
            font-size: 14px;
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: #fff;
                } 
            }
            &.Mui-focused {
                color: #fff;
                .MuiOutlinedInput-notchedOutline {
                    border-color: #fff;
                }
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #fff;
        }
        // .MuiInputLabel-outlined {
        //     &.MuiInputLabel-shrink {
        //         transform: translate(14px, -6px) scale(0.75);
        //     }
        // }
    }
    .agencyButton {
        display: none;
    }
    &.fixedButton {
        .notification__content {
            background-color: transparent;
            padding: 0;
        }
        .buttonGroup {
            button {
                height: 50px;
                max-width: 70px;
                width: 100%;
                box-sizing: border-box;
                position: relative;
                padding: 0;
                font-size: 12px;
                .MuiSvgIcon-root {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    font-size: 20px;
                }
                .buttonText {
                    position: absolute;
                    bottom: 7px;
                    left: 7px;
                    line-height: 1;
                }
            }
            .agencyButton {
                display: inline-flex;
            }
        }
    }
}

.notificationGroupRow {
    margin-bottom: 16px;
}
.notificationContent {
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
}
.notificationGroup {
    background-color: #343a40;
    padding: 12px;
    box-sizing: border-box;
    font-weight: 300;
    transition: all .5s ease;
    overflow: hidden;
    &__iconGroup {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 24px;
        .MuiSvgIcon-root {
            font-size: 20px;
            cursor: pointer;
        }
    }
    &__clear {
        margin-left: 10px;
        display: inline-block;
    }
    .notification__more {
        font-size: 24px;
        &.toggle {
            transform: rotate(180deg);
        }
    }
    &_all {
        overflow-y: scroll;
        .notificationGroup__item {
            &:last-child {
                padding-bottom: 12px;
            }
        }
    }
    &__item {
        border-bottom: 1px solid #777777;
        padding-bottom: 12px;
        margin-bottom: 12px;
        position: relative;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
        // &:nth-child(3) {
        //     margin-top: 12px;
        //     padding-top: 12px;
        //     border-top: 1px solid #777777;
        // }
    }
    &__title {
        font-weight: 500;
    }
    // &__desc {
    //     word-wrap: break-word;
    // }
    &__date {
        font-size: 14px;
        margin-top: 6px;
        padding-right: 24px;
        opacity: .7;
    }
    &__more {
        text-align: center;
        font-size: 14px;
        color: #36a5ff;
        margin-top: 8px;
        span {
            cursor: pointer;
            transition: all .3s ease;
            &:hover {
                color: #fff;
            }
        }
    }
    &__btnRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 6px;
    }
    &__btn {
        max-width: 100%;
        width: 100%;
        background-color: #6c757d;
        color: #fff;
        font-size: 14px;
        border: 2px solid transparent;
        transition: all .5s ease;
        line-height: 17px;
        &:hover {
            border-color: #ccc;
        }
    }
}

.notification-call {
    .notification__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btn-success {
        background-color: #28a745;
    }
    .btn-danger {
        background-color: #dc3545;
    }
}
.billboardSideCount {
    max-width: 224px;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.renderOptionSide {
    display: flex;
    align-items: center;
    &__square {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 8px;
        margin-top: -2px;
    }
    &__status {
        font-size: 14px;
        margin-left: 8px;
        opacity: 0.7;
    }
}
div.projectDiscountActions {
    justify-content: space-between;
    padding: 8px 28px 20px;
    .projectConfirm {
        margin-left: 12px;
        margin-bottom: 0;
    }
}
.billboard-status.MuiGrid-grid-xs-2 {
    max-width: 20%;
    flex-basis: 20%;
}
.totalDiscountText.errorColor {
    color: #cc0000;
}
div.MuiCard-root {
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.5);
}
.baseDicount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    padding-bottom: 4px;
    &__label {
        margin-right: 8px;
    }
    .MuiFormControl-root {
        max-width: 120px;
    }
}
.titleBorder {
    font-size: 18px;
    font-weight: 500;
    position: relative;
    span {
        background-color: #fff;
        position: relative;
        z-index: 2;
        padding-right: 10px;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);
    }
}
.widget-panel {
    .filter-billboard-row {
        top: 21px;
        .filter-billboard__icon {
            right: -24px;
            width: 24px;
            height: 22px;
            min-height: auto;
        }
        .MuiSvgIcon-root {
            font-size: 1.8rem;
        }
    }
    .statusColorBtn {
        top: 5px;
        width: 24px;
        height: 25px;
        min-height: auto;
        display: none;
        .MuiSvgIcon-root {
            font-size: 1.8rem;
        }
    }
}
.modal-billboard-detail {
    .MuiCardHeader-content {
        span {
            font-size: 18px;
        }
    }
}
.statisticTooltip {
    font-size: 13px;
    font-weight: 400;
    padding: 2px;
}
div.tariffAutocomplete {
    padding-left: 10px;
}
.tariffAddress {
    .MuiInputBase-root {
        font-size: 13px;
    }
}
.tariffAddressOption {
    font-size: 14px;
}
.box-title {
    font-size: 18px;
    font-weight: 500;
    margin-right: 12px;
}
.advancedAutocomplete {
    width: 100%;
    height: 40px;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    border: 1px solid #dddddd;
    font-size: 1rem;
    &:focus {
        outline-color: #3f51b5;
    }
    &-city {
        color: #777777;
        font-size: 13px;
    }
    &-row {
        position: relative;
        .done-icon {
            position: absolute;
            right: 30px;
            top: 10px;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
    &-icon {
        &.MuiSvgIcon-root {
            position: absolute;
            right: 8px;
            top: 10px;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
}
div.persentImportExcel {
    font-size: 10px;
    color: #fff;
}
.advancedOption {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    margin-top: -3px;
    font-size: 1rem;
    z-index: 999;
    &__list {
        list-style: none;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        max-height: 350px;
        overflow-y: scroll;
    }
    &__item {
        padding: 4px 10px;
        transition: all .3s ease;
        &:hover {
            cursor: pointer;
            background-color: rgb(233, 233, 233);
        }

    }
}

.getAddressAutocomplete {
    width: 100%;
    height: 40px;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    border: 1px solid #dddddd;
    font-size: 1rem;
    min-width: 350px;
    &::placeholder {
        color: rgba(0, 0, 0, 0.54);
    }
    &:focus {
        outline-color: #3f51b5;
    }
    &-city {
        color: #777777;
        font-size: 13px;
    }
    &-row {
        position: relative;
        .done-icon {
            position: absolute;
            right: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
    &-icon {
        &.MuiSvgIcon-root {
            position: absolute;
            right: 8px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
    &-error {
        position: absolute;
        margin-top: 1px;
        border: 1px solid #dddddd;
        padding: 5px;
        font-size: 12px;
        color: #cc000c;
        z-index: 9;
        background-color: #fff;
        border-radius: 5px;
    }
}

.infoWindow {
    &__content {
        margin-bottom: 4px;
    }
    &__link {
        color: blue;
        cursor: pointer;
        line-height: 1.4;
        &:hover {
            text-decoration: none;
        }
    }
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 371px;
    &.full-height {
        height: 100vh;
    }
}

.brandAutocomplete {
    position: relative;
    .addNewBrand {
        position: absolute;
        right: 60px;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: .6;
        font-size: 20px;
        cursor: pointer;
    }
}
.inputDate {
    display: flex;
    justify-content: space-between;
    .inputDate__icon {
        position: absolute;
        right: 35px;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 20px;
        cursor: pointer;
        opacity: .7;
    }
}
.react-datepicker__input-container {
    input {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 5px 10px;
        font-size: 16px;
        box-sizing: border-box;
        &.statusesDate {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #ccc;
            height: auto;
            padding: 0;
            width: 100%;
            max-width: 100px;
            font-size: 14px;
            text-align: center;
            line-height: 1;
            &:focus {
                outline: none;
                border-color: #3f51b5;
            }
        }
        &:focus {
            outline-color: #3f51b5;
        }
    }
}
.status-date-range {
    .inputDate {
        .MuiFormControl-root {
            width: 100%;
            max-width: 100%;
        }
    }
}
.importExcel {
    position: relative;
    &__progress {
        position: absolute;
        right: 10px;
        top: 2px;
        bottom: 0;
        margin: auto;
    }
    .MuiOutlinedInput-root {
        padding-right: 54px;
        padding-bottom: 5px;
    }
}

.rollUp {
    opacity: 0;
    visibility: hidden;
    &None {
        opacity: 1;
        visibility: visible;
    }
} 
.rollUpBtnRow {
    position: absolute;
    left: 0;
    bottom: 5px;
    z-index: 9;
    button.rollUpBtn {
        padding-right: 8px;
        padding-left: 3px;
        margin-left: 3px;
        text-transform: none;
    }
}
header.appBarStatistics {
    box-shadow: none;
}
.appBarStatistics {
    .isFixedSum {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-bottom: 0;
    }
}
.сanvasJSChart {
    width: 100%;
    &__header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
.chartDate {
    display: flex;
    .react-datepicker-wrapper {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.additionalCostFormControl {
    .MuiInputBase-input {
        text-align: center;
        padding: 0 0 6px;
    }
}
button.addAdditionalCost {
    margin-bottom: 14px;
}
div.cardActions {
    justify-content: flex-end;
    padding: 16px;
    padding-top: 0;
}
.link {
    color:#3f51b5;
    cursor: pointer;
}
.print {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 16px;
    &__date {
        position: absolute;
        left: 0;
        font-size: 14px;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }

}
.duplicateConfirm {
    .MuiPaper-root {
        overflow-y: inherit;
    }
    .duplicateConfirm__title {
        padding-bottom: 8px;
        max-height: 166px;
        overflow-y: auto;
        h2 {
            font-size: 16px;
        }
    }
    .duplicateConfirm__close {
        position: absolute;
        right: 5px;
        top: 5px;
    }
    .MuiDialogTitle-root {
        padding-right: 55px;
    }
}
.sidebar_small {
    .sidebar-version {
        bottom: 70px;
    }
}
.overflowScroll {
    overflow-y: auto;
    max-height: 650px;
}
.usage-status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
    &__row {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
.cancelDraggable {
    cursor: auto;
}